<template>
  <default-layout>
    <v-container id="registration-management" fluid tag="section">
      <page-loading :show="isLoading" />
      <v-alert type="error" :value="showAlert" dismissible>
        <div v-html="messageAlert" />
      </v-alert>
      <detail :detail="detail" :permissions="permissions" :allowedActions="allowedActions"></detail>
      <registrant-list
        :items="registrants"
        :meta="{}"
        :permissions="permissions"
        @refreshAction="refreshAction"
      ></registrant-list>
    </v-container>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
const PageLoading = () => import('@/components/content-loading/page-loading.vue');
const Detail = () => import('@/components/registration-management/referral-code/detail');
const RegistrantList = () =>
  import('@/components/registration-management/referral-code/registrant-list');
import { mapState } from 'vuex';

export default {
  components: {
    DefaultLayout,
    PageLoading,
    Detail,
    RegistrantList,
  },
  data() {
    return {
      isLoading: false,
      showAlert: false,
      messageAlert: null,
    };
  },
  computed: {
    ...mapState({
      permissions: (state) => state.referralCode.permissions,
      allowedActions: (state) => state.referralCode.allowedActions,
      detail: (state) => state.referralCode.detail,
      registrants: (state) => state.referralCode.registrants,
    }),
  },
  async mounted() {
    await this.loadDetail();
  },
  methods: {
    async refreshAction() {
      await this.loadDetail();
    },
    async loadDetail() {
      try {
        this.isLoading = true;
        this.$store.commit('referralCode/RESET_DEFAULT');
        const response = await this.$store.dispatch(
          'referralCode/getDetailInitData',
          this.$route.params.uuid,
        );
        console.log('DETAIL KODE REFERRAL: ', response);
      } catch (e) {
        this.showAlert = true;
        this.messageAlert = e;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
